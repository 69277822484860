/* whole page */
.home {
    --sizeSectionHeight: 100vh;
}

/* hero */
.hero {
    /* border: solid green 2px; */
    box-sizing: border-box;
    height: var(--sizeSectionHeight);
    display: flex;

    /* needed for scroll snapping to work */
    scroll-snap-stop: normal;
    scroll-snap-align: end;

    /* needed for animateOrbit.js to work */
    position: relative;
    overflow: hidden;
}

.heroTitleContainer {
    text-align: center;
    margin: auto;

    /* needed for animateOrbit.js to work */
    position: relative;
}

.heroTitleContainer h3:first-of-type {
    margin-top: 24px;
}

.heroTitleContainer h3:last-of-type {
    margin-top: 12px;
}

.heroImg {
    --imgSize: 200px;
    max-width: var(--imgSize);

    /* needed for animateOrbit.js to work */
    position: absolute;
    opacity: 0;
    transition: opacity 1s;
}

@media all and (max-width: 750px) {

    .heroTitleContainer h1,
    .heroTitleContainer h1>* {
        font-size: 4rem;
        letter-spacing: 1.5px;
    }

    .heroTitleContainer h3 {
        padding-left: 16px;
        padding-right: 16px;
    }

    .heroTitleContainer h3,
    .heroTitleContainer h3>* {
        font-size: 1.25rem;
    }

    .heroTitleContainer h3:first-of-type {
        margin-top: 16px;
    }

    .heroTitleContainer h3:last-of-type {
        margin-top: 8px;
    }

}