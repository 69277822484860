.footer {
  background-color: var(--primaryColor);
  box-sizing: border-box;
  height: var(--footerHeight);
  display: grid;
  /* center content on y axis */

  /* make footer stick to bottom of page */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--footerHeight)
}

.footer p {
  color: var(--colorTertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-top: 1.5px solid var(--secondaryColor); */
  margin-bottom: 8px;
  text-align: center;
}

@media all and (max-width: 750px) {
  .footer p {
    font-size: 0.8rem;
  }
}