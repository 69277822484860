/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


/* variables */
:root {
  --fontHeading: "Bodoni Moda";
  --fontSubHeading: "Playfair Display";
  --fontBodyCopy: "Montserrat";

  --colorPrimary: #000000;
  --colorPrimaryOpaque: #000000cc;
  --colorSecondary: #faf8f9;
  --colorSecondaryOpaque: #faf8f9cc;
  --colorTertiary: #2c2c2c;
  --colorAccent1: #163342;
  --colorAccent2: #ad8440;

  --sizePageSideMargins: 40px;
  --sizeSectionGap: 300px;
  --sizeHeadingBodyCopyGap: 16px;
  --sizeNavbarHeight: 72px;
  --sizeFooterHeight: 60px;

  --timeAnimationReset: 2000ms;
}


/* base styles and classes */
* {
  margin: 0;
  padding: 0;
  font-family: var(--fontBodyCopy), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  /* needed for scroll snapping to work */
  scroll-snap-type: y mandatory
}
body {
  font-size: 16px;
  background: var(--colorPrimary);
}
body > * {
  color: var(--colorSecondary);
}
/* .h1, .h2, etc. is required for animateCharColors.js to work */
h1, h2, h3, h4, .h1, .h2, .h3, .h4, .userSelectNone {
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
h1, .h1 {
  font-size: 6rem;
  font-weight: 700;
  font-family: var(--fontHeading), sans-serif;
  letter-spacing: 2px;
}
h2, .h2 {
  font-size: 2.4rem;
  font-weight: 200;
  font-family: var(--fontSubHeading), sans-serif;
}
h3, .h3 {
  font-size: 1.8rem;
  font-weight: 200;
  font-family: var(--fontSubHeading), sans-serif;
  letter-spacing: 1px;
}
h4, .h4 {
  font-size: 1.2rem;
  font-weight: 500;
  font-family: var(--fontBodyCopy), sans-serif;
}
p, .p, button, input, textarea {
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--fontBodyCopy), sans-serif;
}
ul {
  list-style-type: none;
}
.pageSideMargins {
  padding-left: var(--sizePageSideMargins) !important;
  padding-right: var(--sizePageSideMargins) !important;
}
/* needed for animateCharColors.js to work */
.animateCharColorsAccent1 {
  transition: color var(--timeAnimationReset);
}
.animateCharColorsAccent1:hover {
  color: var(--colorAccent1);
  transition: color 0ms;
}
.animateCharColorsAccent2 {
  transition: color var(--timeAnimationReset);
}
.animateCharColorsAccent2:hover {
  color: var(--colorAccent2);
  transition: color 0ms;
}


.loader-wrapper {
  width: 10%;
  height: 10%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #242f3f;
  display:flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #Fff;
  animation: loader 2s infinite ease;
}
.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
  0% { transform: rotate(0deg);}
  25% { transform: rotate(180deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(360deg);}
  100% { transform: rotate(360deg);}
}
@keyframes loader-inner {
  0% { height: 0%;}
  25% { height: 0%;}
  50% { height: 100%;}
  75% { height: 100%;}
  100% { height: 0%;}
}
/* .fontHeading {  
  font-family: var(--fontHeading), sans-serif !important;
  font-weight: 400;
}
.fontSubHeading {  
  font-family: var(--fontSubHeading), sans-serif;
  font-weight: 400;
}
.fontBodyCopy {  
  font-family: var(--fontBodyCopy), sans-serif;
  font-weight: 400;
} */
/* .btn {
  background: var(--secondaryColor);
  padding: 9px 24px;
  border-radius: 4px;
  color: var(--primaryColor);
  cursor: pointer;
  border: none;
  box-shadow: 3px 3px 6px #00000040;
  transition: box-shadow 75ms;
}
.btn:hover {
  color: var(--secondaryColor);
  background-color: var(--primaryColor);
  box-shadow: 1px 1px 4px #00000050;
}
.centerButtonWrapper {
  display: flex;
  justify-content: center;
}
.loadingText {
  font-weight: bold;
} */
/* .cta {
  display: grid;
  grid-template-columns: 3.25fr 2fr;
  align-items: start;
  grid-gap: 40px;
} */


/* forms */
/* label {
  display: block;
  margin: 0 auto 20px;
}
label p {
  margin-bottom: 6px;
}
input,
textarea {
  padding: 8px 6px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  resize: none;
}
textarea {
  min-height: 160px;
}
.err {
  color: #ff0000;
  background: #ffa3b3;
  border: 1px solid #ff0000;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
  text-align: center;
} */
