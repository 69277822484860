/* center all content on page */
.app {
  display: flex;
}
.pageWrapper {
  flex-grow: 1;

  /* make footer stick to bottom of page */
  position: relative;
  min-height: 100vh;
}
.pageContentWrapper {
  /* make footer stick to bottom of page */
  padding-bottom: var(--footerHeight);
}

